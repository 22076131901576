import React, { useEffect, useState } from 'react'
import styles from './WonderfulPage.module.scss'
import { IFlex, IShortApart } from '../../types/Models/types';
import { collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../../fireebase';
import Map from '../../Components/Map/Map';
import { localisation } from '../../localisation';
import Reviews from '../../Components/Reviews/Reviews';
import HorizontalInstant from '../../Components/HorizontalInstant/HorizontalInstant';
import OpacityInstant from '../../Components/OpacityInstant/OpacityInstant';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Header from '../../Components/Header/Header';
import WonderfulHeader from '../../Components/WonderfulHeader/WonderfulHeader';
interface IWonderfulPageProps {
    lang: string,
    changeLang: (newLang: string) => void
}

const flex = [
    { text: "Выебываемся вином", image: "/images/flex-image.jpg" },
    { text: "Выебываемся новой техникой", image: "/images/flex-image2.jpg" },
    { text: "Выебываемся косметикой", image: "/images/flex-image3.jpg" },
    { text: "И много чем другим тоже выебываемся, главное отфоткать всё", image: "/images/flex-image4.jpg" }
] as IFlex[]
const WonderfulPage: React.FC<IWonderfulPageProps> = ({ lang, changeLang }) => {

    const [currentPosScroll, setCurrentPosScroll] = useState(0);
    const [currentPosScrollFlex, setCurrentPosScrollFlex] = useState(0);
    const [aparts, setAparts] = useState<IShortApart[]>([]);
    const [scrollY, setScrollY] = useState(0);
    const [galleryImages, setGalleryImages] = useState<string[]>([]);
    const [flexSlideLtr, setFlexSlideLtr] = useState(true);
    useEffect(() => {
        const fetchAparts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "short_aparts"));
                const apartsData: IShortApart[] = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                })) as IShortApart[];
                setAparts(apartsData);
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }

            try {
                // Ссылка на папку с изображениями
                const folderRef = ref(storage, "Gallery");

                // Получаем список всех файлов в папке
                const result = await listAll(folderRef);

                // Получаем URL для каждого изображения
                const urlPromises = result.items.map(item => getDownloadURL(item));
                const urls = await Promise.all(urlPromises);

                if (urls) {
                    setGalleryImages(urls)
                }
            } catch (error) {
                console.error('Ошибка при получении ссылок на фотографии:', error);
                return [];
            }
        };

        fetchAparts();
        const handleScroll = () => {
            setScrollY(window.scrollY);
            console.log(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    const plusCurrentPos = () => {
        if (currentPosScroll < aparts.length) {
            setCurrentPosScroll(currentPosScroll + 1);
        }
    }

    const minusCurrentPos = () => {
        if (currentPosScroll > 0) {
            setCurrentPosScroll(currentPosScroll - 1);
        }
    }

    const plusCurrentFlex = () => {

        if (flexSlideLtr) {
            if (currentPosScrollFlex === flex.length - 2) {
                setFlexSlideLtr(false);
            }
            setCurrentPosScrollFlex(currentPosScrollFlex + 1)
        }
        else {
            if (currentPosScrollFlex === 1) {
                setFlexSlideLtr(true);
            }
            setCurrentPosScrollFlex(currentPosScrollFlex - 1);
        }
    }

    return (
        <div className={styles.WonderfulPage}>
            <div className={`${styles.ContainerUp}`}>
                <div className={`${styles.container}`}>
                    <WonderfulHeader />
                </div>
            </div>
            <div className={`${styles.ContainerDown}`}>
                <div className={`${styles.ContainerLeft}`}>
                    <div className={styles.ContainerLeftUp}>
                        <div className={`${styles.container} ${styles.ContainerLeftUpLeft}`}>
                            <div className={styles.content}>
                                <span className={`${styles.big} ${styles.bold}`}>Добро пожаловать в Cozy Aparts</span>
                                <span className={`${styles.big} ${styles.thin}`}>Комфортный отдых в центре мегаполиса
                                </span>
                                <span className={`${styles.WhiteButton}`} style={{
                                    marginRight: "auto",
                                    marginTop: "15px"
                                }}>Узнать о нас больше</span>
                            </div>
                        </div>
                        <div className={`${styles.container} ${styles.ContainerLeftUpRight}`}>
                            <div className={styles.content}>

                                <div className={`${styles.flexes}`} style={{
                                    width: `${flex.length * 100}%`,
                                    transform: `translateX(${-currentPosScrollFlex * 100 / flex.length}%)`
                                }}>
                                    {flex.map((myFlex) =>
                                        <div className={styles.flex} style={{
                                            width: `calc(100% / ${flex.length})`
                                        }}>
                                            <div className={styles.imgWrapper}>
                                                <img src={myFlex.image} />
                                            </div>
                                            <div className={styles.shadow}></div>
                                            <span className={`${styles.big} ${styles.thin}`}>
                                                {myFlex.text}
                                            </span>
                                        </div>
                                    )
                                    }
                                </div>
                                <div onClick={() => {
                                    plusCurrentFlex();
                                }}
                                    className={`${styles.slide} ${!flexSlideLtr ? styles.slideLeft : styles.slideRight}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ContainerLeftDown}>
                        <div className={`${styles.container} ${styles.ContainerLeftDownLeft}`}>
                            {/* <Map /> */}
                        </div>
                        <div className={`${styles.container} ${styles.ContainerLeftDownRight}`}>
                            <img className={styles.arrow} src='/images/arrow_bej.svg'></img>
                            <div className={styles.info}>
                                <a href="tel:+79299023710">
                                    <img src='/images/wacap.svg' />
                                    <span>
                                        +7 (929) 902-37-10
                                    </span>
                                </a>
                                <a href="https://t.me/cozzyaparts">
                                    <img src='/images/tg.svg' />
                                    <span>
                                        @cozzyaparts
                                    </span>
                                </a>
                            </div>
                            <div className={styles.content}>
                                <span className={`${styles.big} ${styles.bold}`}>Contact</span><span className={`${styles.big} ${styles.thin}`}>us</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={`${styles.ContainerCentre}`}>
                    <span className={styles.ReviewsText}>
                        Our Reviews
                    </span>
                    <Reviews />
                </div> */}
                <div className={`${styles.ContainerCentre}`}>
                    <HorizontalInstant startScroll={250} scrollY={scrollY} color='rgb(222, 222, 222)'
                        text1={
                            //@ts-ignore
                            localisation[lang]["welcome_to_cozy"].split(" ").slice(0, Math.floor(localisation[lang]["welcome_to_cozy"].split(" ").length / 2)).join(" ")}
                        text2={
                            //@ts-ignore
                            localisation[lang]["welcome_to_cozy"].split(" ").slice(Math.floor(localisation[lang]["welcome_to_cozy"].split(" ").length / 2)).join(" ")}
                        offset={160} />
                    <OpacityInstant startScroll={400} scrollY={scrollY} color='rgb(222, 222, 222)' text={
                        //@ts-ignore
                        localisation[lang]["long_flex"]} />
                </div>
                <div className={`${styles.ContainerRight}`}>
                    <div className={`${styles.container} ${styles.ContainerRightUp}`}>
                        <div className={styles.content}>
                            <div className={styles.aparts} style={{
                                width: `${aparts.length * 100}%`,
                                transform: `translateX(${-currentPosScroll * 100 / aparts.length}%)`
                            }}>
                                {aparts.map((apart) =>
                                    <div className={styles.apart} style={{
                                        width: `calc(100% / ${aparts.length})`
                                    }}>
                                        <div className={styles.imgWrapper}>
                                            <img src={apart.img} />
                                        </div>
                                        <div className={styles.textBlock}>
                                            <span className={styles.text}>Tower: {apart.tower}</span>
                                            <span className={styles.text}>Price: from <span className={styles.bold}>{apart.price}Р</span></span>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div onClick={() => {
                                minusCurrentPos();
                            }} style={{
                                opacity: currentPosScroll < 1 ? 0 : 0.5,
                                pointerEvents: currentPosScroll < 1 ? "none" : "all",
                            }}
                                className={`${styles.slide} ${styles.slideLeft}`}></div>
                            <div onClick={() => {
                                plusCurrentPos();
                            }}
                                style={{
                                    opacity: currentPosScroll >= aparts.length - 1 ? 0 : 0.5,
                                    pointerEvents: currentPosScroll >= aparts.length - 1 ? "none" : "all",
                                }}
                                className={`${styles.slide} ${styles.slideRight}`}></div>
                        </div>
                    </div>
                    <div className={`${styles.container} ${styles.ContainerRightDown}`}>
                        <div className={styles.content}>
                            {aparts && aparts[currentPosScroll] &&
                                <div className={styles.book}>
                                    <div className={styles.Name}>
                                        <span>{aparts[currentPosScroll].Name}</span>
                                    </div>
                                    <div className={styles.form}>
                                        <div className={styles.date}>
                                            <span className={styles.bookTitle}>
                                                Chose Dates
                                            </span>
                                            <div className={styles.dateInput}>
                                                <div className={`${styles.from} ${styles.dateField}`}>
                                                    <span className={styles.textDate}>
                                                        03.10.2024
                                                    </span>
                                                </div>
                                                -
                                                <div className={`${styles.to} ${styles.dateField}`}>
                                                    <span className={styles.textDate}>
                                                        04.10.2024
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${styles.guest} ${styles.InputField}`}>
                                            <span className={styles.bookTitle}>
                                                Select the number of guests
                                            </span>
                                            <div className={styles.guests}>2</div>
                                        </div>
                                        <div className={`${styles.Name} ${styles.InputField}`}>
                                            <span className={styles.bookTitle}>
                                                Enter your name
                                            </span>
                                            <input placeholder='name'></input>
                                        </div>
                                        <div className={`${styles.Name} ${styles.InputField}`}>
                                            <span className={styles.bookTitle}>
                                                Enter your phone number
                                            </span>
                                            <input placeholder='phone'></input>
                                        </div>
                                        <div className={`${styles.Ask} ${styles.InputField}`}>
                                            <span className={styles.bookTitle}>
                                                Ask something
                                            </span>
                                            <textarea placeholder=''></textarea>
                                        </div>
                                    </div>
                                    <div className={styles.bookButton}>Book</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={`${styles.ContainerCentre}`} style={{ paddingBottom: 0 }}>
                    <HorizontalInstant startScroll={1600} scrollY={scrollY} color='rgb(222, 222, 222)'
                        text1={"Как до нас добраться"}
                        text2={"И отзывы"}
                        offset={160} />

                </div>
                <div className={`${styles.ContainerMap}`}>
                    <div className={styles.content}>
                        <div className={styles.left}>
                            <div className={styles.reviews}>
                                <div className={styles.links}>
                                    <a href='https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2'>
                                        <div className={styles.thin}>
                                            Avito
                                        </div>
                                    </a>
                                </div>
                                <div className={styles.links}>
                                    <a href='https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2'>
                                        <div className={styles.thin}>
                                            Ostrovok
                                        </div>
                                    </a>
                                </div>
                                <div className={styles.links}>
                                    <a href='https://www.avito.ru/brands/i364874047/all/kvartiry?gdlkerfdnwq=101&shopId=4821913&page_from=from_item_card&iid=4253096031&context=&sellerId=19f16943bbd81ced372a7489020778c2'>
                                        <div className={styles.thin}>
                                            OneTwoTrip
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <Map />
                        </div>
                        <Reviews />
                    </div>
                </div>
                <div className={`${styles.ContainerCentre}`} style={{ paddingBottom: "50px" }}>
                    <HorizontalInstant startScroll={2620} scrollY={scrollY} color='rgb(222, 222, 222)'
                        text1={"Галлерея"}
                        text2={"Cozy Aparts"}
                        offset={150} />
                    {/* <OpacityInstant startScroll={2620} scrollY={scrollY} color='rgb(222, 222, 222)' text={
                        "Галерея Cozy Aparts"
                    }/> */}
                </div>
                <div className={styles.MasonryGallery}>

                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 480: 2, 768: 3, 1200: 3 }} gutter={"10px"}
                    >
                        <Masonry>
                            {galleryImages.map((img, i) =>
                                <>
                                    <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                                </>
                            )}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>

            </div>
            <div className={`${styles.ContainerUp}`}>
                <div className={`${styles.container}`} style={{
                    backgroundColor: "transparent"
                }}>
                    <div className={styles.content}>
                        <div className={styles.logo}>Cozy Aparts</div>
                        <div className={styles.nav}>
                            <div className={styles.button}>
                                About
                            </div>
                            <div className={styles.button}>
                                Gallery
                            </div>
                            <div className={styles.button}>
                                Apartments
                            </div>
                            <div className={styles.button}>
                                Book
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WonderfulPage