import React from 'react'

const Map = () => {
    return (
        <div style={{ position: "relative", overflow: "hidden", height: "100%", width: "700px" }}>
            <iframe src="https://yandex.ru/map-widget/v1/?ll=37.536098%2C55.751940&mode=search&oid=119138966536&ol=biz&z=15.59"
                width="100%"
                height="100%"
                frameBorder={1}
                allowFullScreen={true} style={{ position: "relative" }}>
            </iframe>
        </div >
    )
}

export default Map