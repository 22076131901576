import React, { useEffect, useState } from 'react'
import styles from './Apart.module.scss'
import { useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db, storage } from '../../fireebase'
import { IApart } from '../../types/Models/types'
import Header from '../../Components/Header/Header'
import HorizontalInstant from '../../Components/HorizontalInstant/HorizontalInstant'
import OpacityInstant from '../../Components/OpacityInstant/OpacityInstant'
import { localisation } from '../../localisation';
import { getDownloadURL, listAll, ref } from 'firebase/storage'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

interface IApartProps {
    lang: string,
    changeLang: (newLang: string) => void
}


const Apart: React.FC<IApartProps> = ({ lang, changeLang }) => {
    const apartName = useParams().nameApart;
    const [apart, setApart] = useState<IApart>();
    const [images, setImages] = useState<string[]>([])
    const [scrollY, setScrollY] = useState(0);
    const [isVisibleHeader, setIsVisibleHeader] = useState('NonVisible');

    useEffect(() => {
        const fetchAparts = async () => {
            try {
                // Получаем ссылку на документ
                const collectionRef = collection(db, 'aparts'); // Замените 'Aparts' на имя вашей коллекции

                // Создаем запрос с фильтрацией по значению поля
                const q = query(collectionRef, where("Name", "==", apartName));

                // Выполняем запрос
                const querySnapshot = await getDocs(q);

                // Проверяем, существует ли документ
                if (querySnapshot.empty) {
                } else {

                    querySnapshot.forEach(async (doc) => {
                        setApart(doc.data() as IApart);

                        try {
                            // Ссылка на папку с изображениями
                            const folderRef = ref(storage, doc.data().img);

                            // Получаем список всех файлов в папке
                            const result = await listAll(folderRef);

                            // Получаем URL для каждого изображения
                            const urlPromises = result.items.map(item => getDownloadURL(item));
                            const urls = await Promise.all(urlPromises);

                            if (urls) {
                                setImages(urls)
                            }
                        } catch (error) {
                            console.error('Ошибка при получении ссылок на фотографии:', error);
                            return [];
                        }
                    });
                }
            } catch (error) {
                console.error('Ошибка при получении документа:', error);
            }
        };

        fetchAparts();
        const handleScroll = () => {
            setScrollY(window.scrollY);
            if (window.scrollY > window.innerHeight) {
                setIsVisibleHeader('Visible');
            } else {
                setIsVisibleHeader('NonVisible');
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [apartName])
    var elem = document.querySelector('.masonry-gallery');
    var msnry = new Masonry(elem, {
        itemSelector: '.item',
        columnWidth: '.item',
        percentPosition: true
    });
    return (
        <div className={styles.Apart}>
            <Header visible={isVisibleHeader} color='Black' position='Fixed' lang={lang} changeLang={changeLang} />
            <div className={`${styles.Page} ${styles.firstPage}`}>
                <Header visible={isVisibleHeader === "Visible" ? "NonVisible" : "Visible"} color='White' position='Absolute' lang={lang} changeLang={changeLang} />
                <div className={styles.ImageBlock}>
                    {apart
                        && <img style={{ opacity: apart ? 1 : 0 }} src={images[1]}></img>
                    }
                </div>
                <div className={styles.ContentBlock}>
                    <div className={styles.LogoBlock}>
                        <img src='/images/logo_text.svg' />
                    </div>
                    <div className={styles.TextBlock}>
                        <div className={styles.Left}>
                            {
                                apart &&
                                <div className={styles.TextPrevue} style={{ opacity: apart ? 1 : 0 }}>
                                    <span className={styles.BoldBigText}>
                                        {
                                            //@ts-ignore
                                            localisation[lang]["Price"]} {apart?.price}₽
                                    </span>
                                    <span className={styles.BigText} style={{ maxWidth: 370 }}>
                                        {
                                            //@ts-ignore
                                            localisation[lang]["Tower"]}: {apart?.tower}
                                    </span>
                                    <span className={styles.BigText} style={{ maxWidth: 370 }}>
                                        {
                                            //@ts-ignore
                                            localisation[lang]["Area"]}: {apart?.area}{localisation[lang]["M"]}
                                    </span>
                                    <button className={`${styles.ButtonWhite}`} style={{ marginTop: "15px" }}>{
                                        //@ts-ignore
                                        localisation[lang]["book"]}</button>
                                </div>
                            }
                        </div>
                        <div className={styles.Right}>
                            <div className={styles.buttonsContainer}>
                                <span className={`${styles.buttonBorder} `}>
                                    {
                                        //@ts-ignore
                                        localisation[lang]["description"]
                                    }
                                </span>
                                <span className={`${styles.buttonBorder} `}>
                                    {
                                        //@ts-ignore
                                        localisation[lang]["gallery"]
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={`${styles.Page} ${styles.secondPage}`}>
                <HorizontalInstant startScroll={300} scrollY={scrollY}
                    text1={
                        //@ts-ignore
                        localisation[lang]["welcome_to_cozy"].split(" ").slice(0, Math.floor(localisation[lang]["welcome_to_cozy"].split(" ").length / 2)).join(" ")}
                    text2={
                        //@ts-ignore
                        localisation[lang]["welcome_to_cozy"].split(" ").slice(Math.floor(localisation[lang]["welcome_to_cozy"].split(" ").length / 2)).join(" ")}
                    offset={160} />
                <OpacityInstant startScroll={350} scrollY={scrollY} text={
                    //@ts-ignore
                    localisation[lang]["long_flex"]} />
            </div>
            <div className={`${styles.ThirdPage}`}>
                <div className={styles.MasonryGallery}>

                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 480: 2, 768: 3, 1200: 3 }} gutter={"10px"}
                    >
                        <Masonry>
                            {images.map((img, i) =>
                                <>
                                    <div className={styles.item}><img src={img} alt={`Image ${i}`} /></div>

                                </>
                            )}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
            <div className={`${styles.Page} ${styles.FourthPage}`}>
            </div>
        </div>
    )
}

export default Apart