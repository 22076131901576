import React from 'react'
import styles from './OpacityInstant.module.scss'

interface IOpacityInstantProps {
    startScroll: number,
    scrollY: number,
    text: string,
    color?: string,
}

const OpacityInstant: React.FC<IOpacityInstantProps> = ({ startScroll, scrollY, text, color }) => {
    const endScroll = startScroll + 300;
    return (
        <div className={styles.horOpacity} style={{
            color: color ? color : 'black'
        }}>
            <span className={styles.Text} style={{
                opacity: scrollY < endScroll ? (scrollY - startScroll) / (endScroll - startScroll) : 1
            }}>{text}</span>
        </div >
    )
}

export default OpacityInstant