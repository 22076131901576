import React, { useEffect, useState } from 'react'
import styles from './HorizontalIntanst.module.scss'

interface IHorizontalInstantProps {
    startScroll: number,
    scrollY: number,
    text1: string,
    text2: string,
    color?: string,
    offset: number,
}

const HorizontalInstant: React.FC<IHorizontalInstantProps> = ({ startScroll, scrollY, text1, text2, offset, color }) => {
    const endScroll = startScroll + 300;
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setInnerWidth(window.innerWidth);
        })
    }, [])
    return (
        <div className={styles.horInstant} style={{
            color: color ? color : 'black'
        }}>
            <span className={styles.BigText} style={{
                transform: `translate(${scrollY < endScroll ? -(endScroll - scrollY) / 5 - offset * innerWidth / 2000 : -offset * innerWidth / 2000}px)`
            }}>{text1}</span>
            <span className={styles.BigText} style={{
                transform: `translate(${scrollY < endScroll ? (endScroll - scrollY) / 5 + offset * innerWidth / 2000 : offset * innerWidth / 2000}px)`
            }}>{text2}</span>
        </div >
    )
}

export default HorizontalInstant