export const localisation = {
    "ru": {
        'apartaments': "Апартаменты",
        'gallery': "Галерея",
        'contacts': "Контакты",
        "book": "забронировать",
        'apartaments_moscow_city': "Апартаменты Москва-Сити",
        "comfortable_relax": "Комфортный отдых в центре мегаполиса",
        "Tower": "Башня",
        "Area": "Площадь",
        "Price": "Цена: от",
        "Floor": "Этаж",
        "M": "м²",
        "description": "описание",
        "welcome_to_cozy": "Добро пожаловать в Cozy Aparts",
        "long_flex": 'Жизнь в апартаментах Москва Сити представляет собой симбиоз современности, роскоши и функциональности. Этот образ жизни предназначен для тех, кто ценит комфорт и уровень обслуживания, а также стремится быть в самом эпицентре событий и инноваций.',
        "more": "Подробнее",
        "learn_more": "Узнать больше",
        "fintess_name": "Фитнес и велнесс",
        "fintess_descr_World": "Один из лучших фитнес-клубов Москвы, расположенный прямо в Москва-Сити. Клуб предлагает современные тренажеры, персональные тренировки, бассейны и спа-зоны, создавая идеальные условия для поддержания здорового образа жизни и релаксации после рабочего дня.",
        "fintess_descr_Spa": "Роскошный спа-центр с видами на Москву. Здесь предлагают различные программы по уходу за телом и лицом, массажи и термальные процедуры, чтобы жильцы могли расслабиться и восстановить силы, не покидая пределы Москва-Сити.",
        "kafe_name": "Рестораны и кафе",
        "kafe_descr_Sixty": "Один из самых популярных ресторанов в Москва-Сити, находящийся на 62-м этаже башни 'Федерация'. В меню представлена европейская кухня, а главное преимущество — невероятный вид на панораму Москвы, что создаёт идеальные условия для деловых встреч и романтических ужинов.",
        "kafe_descr_Ovo": "Итальянский ресторан от знаменитого шеф-повара Карло Кракко. Уютная атмосфера и изысканная кухня делают его идеальным местом для ужина после долгого дня, а винная карта впечатлит даже самых взыскательных гурманов.",
        "centre_name": "Торговые и развлекательные центры",
        "centre_descr_Afimol": "Один из крупнейших торгово-развлекательных центров Москвы. Здесь можно найти не только бутики премиальных брендов, но и кинотеатры, кафе, рестораны и игровые зоны для детей. Для жителей Москва-Сити это идеальное место для шопинга и семейного отдыха.",
        "centre_descr_Digital": "Креативное пространство для бизнесменов и стартаперов, где можно организовать деловые мероприятия, воркшопы или воспользоваться коворкингом с современным оборудованием и конференц-залами. Это идеальное место для продуктивной работы в сердце мегаполиса.",
        "park_name": "Зоны отдыха и парки",
        "park_descr_Skver": "Небольшой, но уютный парк с пешеходными зонами и скамейками, где жильцы могут прогуляться и отдохнуть среди современных небоскрёбов. Отличное место для утренних пробежек или коротких перерывов на свежем воздухе.",
        "park_descr_Smotrovaya": "Одна из самых высоких смотровых площадок в Европе, предлагающая панорамные виды на весь город. Это популярное место как для туристов, так и для жильцов, которые могут наслаждаться живописными закатами и захватывающими дух видами прямо рядом с домом."
    },
    "en": {
        'apartaments': "Apartments",
        'gallery': "Gallery",
        'contacts': "Contacts",
        "book": "Book",
        'apartaments_moscow_city': "Apartments Moscow-City",
        "comfortable_relax": "Comfortable stay in the heart of the metropolis",
        "Tower": "Tower",
        "Area": "Area",
        "Price": "Price: from",
        "Floor": "Floor",
        "M": "m²",
        "description": "description",
        "welcome_to_cozy": "Welcome to Cozy Aparts",
        "long_flex": 'Living in the Moscow City apartments is a symbiosis of modernity, luxury, and functionality. This lifestyle is designed for those who value comfort, service quality, and aspire to be at the center of events and innovation.',
        "more": "More",
        "learn_more": "Learn more",
        "fintess_name": "Fitness and Wellness",
        "fintess_descr_World": "One of the top fitness clubs in Moscow, located right in Moscow-City. The club offers modern equipment, personal training, pools, and spa areas, creating ideal conditions for maintaining a healthy lifestyle and relaxing after a long day.",
        "fintess_descr_Spa": "A luxurious spa with views of Moscow. It offers a variety of body and facial treatments, massages, and thermal therapies, allowing residents to relax and rejuvenate without leaving the confines of Moscow-City.",
        "kafe_name": "Restaurants and Cafes",
        "kafe_descr_Sixty": "One of the most popular restaurants in Moscow-City, located on the 62nd floor of the Federation Tower. The menu offers European cuisine, and the main advantage is the incredible view of the Moscow skyline, creating perfect conditions for business meetings and romantic dinners.",
        "kafe_descr_Ovo": "An Italian restaurant by renowned chef Carlo Cracco. The cozy atmosphere and exquisite cuisine make it the perfect spot for dinner after a long day, and the wine list will impress even the most discerning gourmets.",
        "centre_name": "Shopping and Entertainment Centers",
        "centre_descr_Afimol": "One of the largest shopping and entertainment centers in Moscow. Here, you can find premium brand boutiques, movie theaters, cafes, restaurants, and children's play areas. For Moscow-City residents, it is the ideal place for shopping and family leisure.",
        "centre_descr_Digital": "A creative space for business people and startups, where you can organize business events, workshops, or use coworking spaces equipped with modern technology and conference rooms. This is the perfect place for productive work in the heart of the metropolis.",
        "park_name": "Parks and Recreation Areas",
        "park_descr_Skver": "A small but cozy park with walking paths and benches, where residents can take a stroll and relax among modern skyscrapers. It is a great place for morning jogs or short breaks in the fresh air.",
        "park_descr_Smotrovaya": "One of the highest observation decks in Europe, offering panoramic views of the entire city. It is a popular spot for both tourists and residents, who can enjoy picturesque sunsets and breathtaking views right next to their home."
    },
    "fr": {
        'apartaments': "Appartements",
        'gallery': "Galerie",
        'contacts': "Contacts",
        "book": "Réserver",
        'apartaments_moscow_city': "Appartements Moscou-City",
        "comfortable_relax": "Séjour confortable au cœur de la métropole",
        "Tower": "Tour",
        "Area": "Superficie",
        "Price": "Prix: à partir de",
        "Floor": "Étage",
        "M": "m²",
        "description": "description",
        "welcome_to_cozy": "Bienvenue à Cozy Aparts",
        "long_flex": 'Vivre dans les appartements de Moscou-City est une symbiose de modernité, de luxe et de fonctionnalité. Ce mode de vie est conçu pour ceux qui apprécient le confort, le niveau de service et qui aspirent à être au cœur des événements et des innovations.',
        "more": "En savoir plus",
        "learn_more": "Apprendre plus",
        "fintess_name": "Fitness et bien-être",
        "fintess_descr_World": "L'un des meilleurs clubs de fitness de Moscou, situé directement dans Moscou-City. Le club propose des équipements modernes, des entraînements personnalisés, des piscines et des espaces spa, créant des conditions idéales pour maintenir une vie saine et se détendre après une longue journée.",
        "fintess_descr_Spa": "Un spa luxueux avec vue sur Moscou. Il propose une variété de soins pour le corps et le visage, des massages et des thérapies thermales, permettant aux résidents de se détendre et de se ressourcer sans quitter les limites de Moscou-City.",
        "kafe_name": "Restaurants et cafés",
        "kafe_descr_Sixty": "L'un des restaurants les plus populaires de Moscou-City, situé au 62ème étage de la tour Fédération. Le menu propose une cuisine européenne, et l'atout principal est la vue imprenable sur la skyline de Moscou, créant des conditions idéales pour des réunions d'affaires et des dîners romantiques.",
        "kafe_descr_Ovo": "Un restaurant italien du célèbre chef Carlo Cracco. L'atmosphère cosy et la cuisine raffinée en font l'endroit idéal pour dîner après une longue journée, et la carte des vins impressionnera même les gourmets les plus exigeants.",
        "centre_name": "Centres commerciaux et de divertissement",
        "centre_descr_Afimol": "L'un des plus grands centres commerciaux et de divertissement de Moscou. Ici, vous trouverez non seulement des boutiques de marques haut de gamme, mais aussi des cinémas, des cafés, des restaurants et des aires de jeux pour enfants. Pour les résidents de Moscou-City, c'est l'endroit idéal pour faire du shopping et se détendre en famille.",
        "centre_descr_Digital": "Un espace créatif pour les entrepreneurs et les startups, où vous pouvez organiser des événements professionnels, des ateliers ou utiliser des espaces de coworking équipés de technologies modernes et de salles de conférence. C'est l'endroit idéal pour travailler de manière productive au cœur de la métropole.",
        "park_name": "Zones de détente et parcs",
        "park_descr_Skver": "Un petit parc mais agréable avec des sentiers piétons et des bancs, où les résidents peuvent se promener et se détendre parmi les gratte-ciel modernes. C'est un endroit parfait pour faire du jogging matinal ou prendre de courtes pauses au grand air.",
        "park_descr_Smotrovaya": "L'une des plus hautes plates-formes d'observation d'Europe, offrant une vue panoramique sur toute la ville. C'est un lieu populaire tant pour les touristes que pour les résidents, qui peuvent profiter de couchers de soleil pittoresques et de vues à couper le souffle juste à côté de chez eux."
    },
    "de": {
        'apartaments': "Apartments",
        'gallery': "Galerie",
        'contacts': "Kontakte",
        "book": "Buchen",
        'apartaments_moscow_city': "Apartments Moscow-City",
        "comfortable_relax": "Komfortabler Aufenthalt im Herzen der Metropole",
        "Tower": "Turm",
        "Area": "Fläche",
        "Price": "Preis: ab",
        "Floor": "Etage",
        "M": "m²",
        "description": "Beschreibung",
        "welcome_to_cozy": "Willkommen bei Cozy Aparts",
        "long_flex": 'Das Leben in den Moscow City Apartments ist eine Symbiose aus Moderne, Luxus und Funktionalität. Dieser Lebensstil richtet sich an diejenigen, die Komfort und Service schätzen und im Zentrum des Geschehens und der Innovation sein möchten.',
        "more": "Mehr",
        "learn_more": "Erfahren Sie mehr",
        "fintess_name": "Fitness und Wellness",
        "fintess_descr_World": "Einer der besten Fitnessclubs in Moskau, direkt in Moscow-City gelegen. Der Club bietet moderne Geräte, Personal Training, Pools und Spa-Bereiche und schafft ideale Bedingungen für einen gesunden Lebensstil und Entspannung nach einem langen Tag.",
        "fintess_descr_Spa": "Ein luxuriöses Spa mit Blick auf Moskau. Es bietet eine Vielzahl von Körper- und Gesichtsbehandlungen, Massagen und Thermaltherapien, sodass sich die Bewohner entspannen und erholen können, ohne die Grenzen von Moscow-City zu verlassen.",
        "kafe_name": "Restaurants und Cafés",
        "kafe_descr_Sixty": "Eines der beliebtesten Restaurants in Moscow-City, im 62. Stock des Federation Towers gelegen. Die Speisekarte bietet europäische Küche, und der Hauptvorteil ist der unglaubliche Blick auf die Skyline von Moskau, der perfekte Bedingungen für Geschäftstreffen und romantische Abendessen schafft.",
        "kafe_descr_Ovo": "Ein italienisches Restaurant des renommierten Küchenchefs Carlo Cracco. Die gemütliche Atmosphäre und die exquisite Küche machen es zum perfekten Ort für ein Abendessen nach einem langen Tag, und die Weinkarte wird auch die anspruchsvollsten Feinschmecker beeindrucken.",
        "centre_name": "Einkaufs- und Freizeitzentren",
        "centre_descr_Afimol": "Eines der größten Einkaufs- und Freizeitzentren in Moskau. Hier finden Sie nicht nur Premium-Marken-Boutiquen, sondern auch Kinos, Cafés, Restaurants und Spielbereiche für Kinder. Für die Bewohner von Moscow-City ist dies der ideale Ort für Shopping und Familienfreizeit.",
        "centre_descr_Digital": "Ein kreativer Raum für Geschäftsleute und Startups, in dem Sie geschäftliche Veranstaltungen, Workshops organisieren oder moderne Coworking-Bereiche und Konferenzräume nutzen können. Dies ist der perfekte Ort für produktives Arbeiten im Herzen der Metropole.",
        "park_name": "Parks und Erholungsgebiete",
        "park_descr_Skver": "Ein kleiner, aber gemütlicher Park mit Spazierwegen und Bänken, wo die Bewohner spazieren gehen und sich zwischen den modernen Wolkenkratzern entspannen können. Es ist ein großartiger Ort für morgendliche Jogging-Runden oder kurze Pausen an der frischen Luft.",
        "park_descr_Smotrovaya": "Eine der höchsten Aussichtsplattformen Europas, die einen Panoramablick auf die gesamte Stadt bietet. Es ist ein beliebter Ort für Touristen und Bewohner, die malerische Sonnenuntergänge und atemberaubende Ausblicke direkt vor ihrer Haustür genießen können."
    },
    "el": {
        'apartaments': "Διαμερίσματα",
        'gallery': "Γκαλερί",
        'contacts': "Επαφές",
        "book": "Κράτηση",
        'apartaments_moscow_city': "Διαμερίσματα Μόσχα-Σίτι",
        "comfortable_relax": "Άνετη διαμονή στην καρδιά της μητρόπολης",
        "Tower": "Πύργος",
        "Area": "Περιοχή",
        "Price": "Τιμή: από",
        "Floor": "Όροφος",
        "M": "μ²",
        "description": "περιγραφή",
        "welcome_to_cozy": "Καλώς ήρθατε στο Cozy Aparts",
        "long_flex": 'Η ζωή στα διαμερίσματα της Μόσχας Σίτι είναι μια συμβίωση της σύγχρονης ζωής, της πολυτέλειας και της λειτουργικότητας. Αυτός ο τρόπος ζωής προορίζεται για εκείνους που εκτιμούν την άνεση και την ποιότητα υπηρεσιών, και επιθυμούν να βρίσκονται στο επίκεντρο των γεγονότων και της καινοτομίας.',
        "more": "Περισσότερα",
        "learn_more": "Μάθετε περισσότερα"
    },
    "es": {
        'apartaments': "Apartamentos",
        'gallery': "Galería",
        'contacts': "Contactos",
        "book": "Reservar",
        'apartaments_moscow_city': "Apartamentos Moscú-City",
        "comfortable_relax": "Estancia cómoda en el corazón de la metrópoli",
        "Tower": "Torre",
        "Area": "Área",
        "Price": "Precio: desde",
        "Floor": "Piso",
        "M": "m²",
        "description": "descripción",
        "welcome_to_cozy": "Bienvenido a Cozy Aparts",
        "long_flex": 'Vivir en los apartamentos de Moscú City es una simbiosis de modernidad, lujo y funcionalidad. Este estilo de vida está diseñado para aquellos que valoran la comodidad, el nivel de servicio y aspiran a estar en el centro de los eventos y la innovación.',
        "more": "Más",
        "learn_more": "Saber más"
    }
}
