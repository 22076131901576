import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Home from './Pages/Home/Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Apart from './Pages/Apart/Apart';
import WonderfulPage from './Pages/WonderfulPage/WonderfulPage';
import WonderfulAparts from './Pages/WonderfulAparts/WonderfulAparts';

var lang = window.navigator.language.split("-")[0]
const langValue = localStorage.getItem("lang");

if (langValue) {
  lang = langValue;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const changeLang = (newLang: string) => {
  localStorage.setItem("lang", newLang);
  lang = newLang;
  window.location.reload()
}
const router = createBrowserRouter([
  {
    path: "/apartments",
    element: <WonderfulAparts />
  },
  {
    path: "/aparts/:nameApart",
    element: <Apart lang={lang} changeLang={changeLang}></Apart>,
  },
  {
    path: "/",
    // element: <Home lang={lang} changeLang={changeLang}></Home>,
    element: <WonderfulPage lang={lang} changeLang={changeLang}></WonderfulPage>
  },
]);

root.render(
  <RouterProvider router={router}></RouterProvider>
  // <RouterProvider router={router} />
);
