import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { localisation } from '../../localisation';
interface IHeaderProps {
    color: string;
    position: string;
    lang: string,
    changeLang: (newLang: string) => void,
    visible: string;
}

const Header: React.FC<IHeaderProps> = ({ color, position, changeLang, lang, visible }) => {
    const [isOpenLang, setIsOpenLang] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    useEffect(() => {
        if (visible === 'NonVisible') {
            setIsOpenLang(false)
        }
    }, [visible])

    const handleOpenLang = (open: boolean) => {
        setIsOpenLang(open);
    }
    const handleSetMenu = (open: boolean) => {
        setIsOpenMenu(open);
        document.body.style.overflow = open ? "hidden" : "auto";
        handleOpenLang(false);
    }
    return (
        <div className={`${styles.Header}  ${styles[visible]} ${styles[color]} ${styles[position]}`}>
            <div className={styles.left}>
                <span className={styles.buttons}>+7 (910) 181-15-08</span>
            </div>
            <div className={styles.centre}>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["apartaments"]}</span>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["gallery"]}</span>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["contacts"]}</span>
            </div>
            <div className={styles.right}>
                {position === "Absolute"
                    ?
                    <span className={`${styles.CurrentLanguage} ${styles.buttons}`} onClick={() => handleOpenLang(!isOpenLang)}>{lang}</span>
                    :
                    <button className={`${styles.buttons}`}>{
                        //@ts-ignore
                        localisation[lang]["book"]}</button>
                }
                <div className={`${styles.langBlock} ${isOpenLang ? styles.OpenLang : styles.CloseLang}`}>
                    {Object.keys(localisation).map((_lang) => (
                        <span key={_lang} onClick={() => changeLang(_lang)} className={`${styles.lang} ${styles.buttons}`}>{_lang}</span>
                    ))}
                </div>
            </div>
            <div className={styles.buttonMobile} onClick={() => { handleSetMenu(true) }}>

            </div>
            <div className={`${styles.Menu} ${isOpenMenu ? styles.Open : styles.Close}`}>
                <div className={styles.CloseButton} onClick={() => { handleSetMenu(false); document.body.style.overflow = "auto"; }}></div>

                <span className={`${styles.CurrentLanguage} ${styles.buttons} ${styles.CurrentLanguageMenu}`} onClick={() => handleOpenLang(!isOpenLang)}>{lang}</span>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["apartaments"]}</span>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["gallery"]}</span>
                <span className={styles.buttons}>{
                    //@ts-ignore
                    localisation[lang]["contacts"]}</span>
                <div className={`${styles.langBlock} ${styles.langBlockMenu} ${isOpenLang ? styles.OpenLang : styles.CloseLang}`}>
                    {Object.keys(localisation).map((_lang) => (
                        _lang !== lang &&
                        <span key={_lang} onClick={() => changeLang(_lang)} className={`${styles.lang} ${styles.buttons}`}>{_lang}</span>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default Header