import React from 'react'
import styles from './WonderfulHeader.module.scss'

const WonderfulHeader = () => {
    return (
        <div className={styles.header}>
            <div className={styles.logo}><img src='/images/logo_text.svg' /></div>
            <div className={styles.nav}>
                <a className={styles.button}>
                    About
                </a>
                <a className={styles.button}>
                    Gallery
                </a>
                <a href='/apartments' className={styles.button}>
                    Apartments
                </a>
                <a className={styles.button}>
                    Book
                </a>
            </div>
            <div className={styles.menuButton} />
        </div>
    )
}

export default WonderfulHeader