import React from 'react'

const Reviews = () => {
    return (
        <iframe style={{ flex: 2, height: "100%", borderRadius: "0px", boxSizing: "border-box", overflow: "scroll", backgroundColor: "white" }}
            src="https://yandex.ru/maps-reviews-widget/119138966536?comments">
        </iframe>
    )
}

export default Reviews